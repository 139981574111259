/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import { Button, TextField, Box, Typography, Select, MenuItem, Checkbox } from '@mui/material';
import { CompactPicker } from 'react-color'
import { useQuery, useMutation, gql } from '@apollo/client';
import { ToastDataContext } from 'contexts';
import { ReactComponent as AsinManagerActiveIcon } from 'assets/sidenav/asin-active.svg';
import { Buffer } from 'buffer';
import { Image } from 'image-js';
import { ALL_REGIONS } from 'utils/constants';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import PlatformRegionConfigDropdown from 'pages/Common/PlatformRegionConfigDropdown';

const UpdateTenantForm = ({ tenant, onDone, formId, onSaving, onChangesMade, onLoaded }) => {
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(tenant?.title);
  const [path, setPath] = useState(tenant?.tenant);
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [subscriptionScope, setSubscriptionScope] = useState(tenant?.subscriptionScope ?? 'ACCOUNT');
  const [subscriptionId, setSubscriptionId] = useState(tenant?.subscriptionId);
  const [subscriptionAccessControl, setSubscriptionAccessControl] = useState(tenant?.subscriptionAccessControl ?? false);
  const [subscriptionAccessControlEffectiveDate, setSubscriptionAccessControlEffectiveDate] = useState(tenant?.subscriptionAccessControlEffectiveDate ? dayjs(tenant?.subscriptionAccessControlEffectiveDate) : null);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [navLogo, setNavLogo] = useState(tenant?.navLogo ?? null);
  const [loginLogo, setLoginLogo] = useState(tenant?.loginLogo ?? null);
  const [navColor, setNavColor] = useState(tenant?.navColor ?? '#1C1221');
  const [buttonColor, setButtonColor] = useState(tenant?.buttonColor ?? '#881CE0');
  const { setToast } = useContext(ToastDataContext);

  const isAllRegionsSelected = () => {
    for (let i = 0; i < selectedRegions.length; i += 1) {
      if (selectedRegions[i].id === ALL_REGIONS) {
        return true;
      }
    }
    return false;
  }

  const GET_INFO_QUERY = gql`
    query GetInfo {
      getFeatures {
        id
        name
        description
      }
      getRegions {
        baseUrl
        id
        currency
        iso
        name
        nativeName
        asinCount
        platform
      }
    }
  `;


  const processData = (data) => {
    if (data?.getFeatures) {
      setFeatures(data.getFeatures);
      setRegions(data.getRegions);

      const chosen = [];
      for (let i = 0; i < tenant?.configuredFeatures?.length; i += 1) {
        for (let j = 0; j < data.getFeatures.length; j += 1) {
          if (data.getFeatures[j].id === tenant?.configuredFeatures[i].id) {
            chosen.push(data.getFeatures[j]);
            break;
          }
        }
      }
      setSelectedFeatures(chosen);

      const chosenRegions = [];
      for (let i = 0; i < tenant?.configuredRegions?.length; i += 1) {
        for (let j = 0; j < data.getRegions.length; j += 1) {
          if (data.getRegions[j].id === tenant?.configuredRegions[i].id) {
            chosenRegions.push(data.getRegions[j]);
            break;
          }
        }
      }

      setSelectedRegions(chosenRegions);

      setLoading(false);
      if (onLoaded) {
        onLoaded();
      }
    }
  };

  const { refetch } = useQuery(GET_INFO_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: processData,
    onError: (e) => {
      setToast({ type: 'error', message: e.message });
    },
  });

  const UPSERT_TENANT_MUTATION = gql`
    mutation UpsertTenant($id: ID, $name: String, $title: String, $navColor: String, $buttonColor: String, 
                          $navLogo: String, $loginLogo: String, $features: [Int], $regions: [String], 
                          $subscriptionScope: String, $subscriptionId: String, $subscriptionAccessControl: Boolean, $subscriptionAccessControlEffectiveDate: DateTime
    ) {
      upsertTenant(id: $id, name: $name, title: $title, navColor: $navColor, buttonColor: $buttonColor, 
                   navLogo: $navLogo, loginLogo: $loginLogo, features: $features, regions: $regions,
                   subscriptionScope: $subscriptionScope, subscriptionId: $subscriptionId, subscriptionAccessControl: $subscriptionAccessControl, subscriptionAccessControlEffectiveDate:$subscriptionAccessControlEffectiveDate
      )
    }
  `;

  const [upsertTenant] = useMutation(UPSERT_TENANT_MUTATION, {
    onError: (e) => {
      setLoading(false);
      setFormErrors([{ formError: e.message }].reduce((acc, err) => ({ ...acc, ...err }), {}));
    },
  });

  const handleResetForm = () => {
    setNavLogo(tenant?.navLogo ?? null);
    setLoginLogo(tenant?.loginLogo ?? null);
    setNavColor(tenant?.navColor ?? '#1C1221');
    setButtonColor(tenant?.buttonColor ?? '#881CE0');
    setTitle(tenant?.title);
    setPath(tenant?.tenant);
    setSubscriptionAccessControl(tenant?.subscriptionAccessControl);
    setSubscriptionId(tenant?.subscriptionId);
    setSubscriptionAccessControlEffectiveDate(tenant?.subscriptionAccessControlEffectiveDate);
    setSubscriptionScope(tenant?.subscriptionScope);
    refetch();
  }

  // validate form values
  const validateForm = () => {
    const errors = [];
    if (title.length > 100) {
      errors.push({ title: 'Title must be under 100 characters' });
    }
    if (path.length > 50) {
      errors.push({ path: 'Path must be under 50 characters' });
    }
    if (!/^[a-z0-9]*$/.test(path)) {
      errors.push({ path: 'Path must only contain alphanumeric characters and no spaces' });
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // validate form input
    const errors = validateForm();
    // set form errors
    setFormErrors(errors.reduce((acc, err) => ({ ...acc, ...err }), {}));
    if (errors.length === 0) {
      try {
        setLoading(true);
        onSaving(true);
        const regionsToUse = isAllRegionsSelected() ? [ALL_REGIONS] : selectedRegions.map(r => r.id);
        upsertTenant({
          variables: {
            id: tenant ? tenant.id : null,
            name: path,
            title,
            navColor,
            buttonColor,
            navLogo,
            loginLogo,
            features: selectedFeatures.map(f => f.id),
            regions: regionsToUse,
            subscriptionScope,
            subscriptionId,
            subscriptionAccessControl, 
            subscriptionAccessControlEffectiveDate
          },
          onCompleted: () => {
            setToast({ type: 'success', message: tenant ? 'Tenant updated' : 'Tenant added' });
            setLoading(false);
            onSaving(false);
            onDone();
          },
        });
      } catch (err) {
        setFormErrors({ formError: err.message });
        onSaving(false);
      }
    }
  }

  const handleSelectNavigationLogo = (e) => {
    if (e.target.files?.length > 0) {
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = async readerEvent => {
        const content = readerEvent.target.result; // this is the content!
        // eslint-disable-next-line new-cap
        const imageBuffer = new Buffer.from(content, 'binary');
        const loadedImage = await Image.load(imageBuffer);
        let converted = null;
        if (loadedImage.width > 640) {
          converted = await loadedImage.resize({ width: 640 }).toBase64();
        } else {
          converted = await loadedImage.toBase64();
        }
        const src = `data:image/png;base64,${converted}`;
        setNavLogo(src);
      }
      if (onChangesMade) {
        onChangesMade();
      }
    }
  }

  const handleSelectLoginLogo = async (e) => {
    if (e.target.files?.length > 0) {
      const reader = new FileReader();
      reader.readAsBinaryString(e.target.files[0]);
      reader.onload = async readerEvent => {
        const content = readerEvent.target.result; // this is the content!
        // eslint-disable-next-line new-cap
        const imageBuffer = new Buffer.from(content, 'binary');
        const loadedImage = await Image.load(imageBuffer);
        let converted = null;
        if (loadedImage.width > 640) {
          converted = await loadedImage.resize({ width: 640 }).toBase64();
        } else {
          converted = await loadedImage.toBase64();
        }
        const src = `data:image/png;base64,${converted}`;
        setLoginLogo(src);
      }
      if (onChangesMade) {
        onChangesMade();
      }
    }
  }

  return (
    <form id={formId} onSubmit={handleSubmit} onReset={handleResetForm}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {formErrors.formError && <Box sx={{ color: 'red', mb: 3 }}>{formErrors.formError}</Box>}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
            <TextField
              disabled={loading}
              required
              id="client-name"
              data-cy="client_name"
              label="Tenant Name"
              autoComplete="no"
              type="text"
              variant="standard"
              fullWidth
              value={title || ''}
              onChange={(e) => {
                setTitle(e.target.value);
                if (onChangesMade) {
                  onChangesMade();
                }
              }}
              error={Boolean(formErrors.title)}
              helperText={formErrors.title}
            />
            <TextField
              disabled={loading}
              required
              id="client-name"
              data-cy="client_name"
              label="Path"
              autoComplete="no"
              type="text"
              variant="standard"
              fullWidth
              value={path || ''}
              onChange={(e) => {
                setPath(e.target.value)
                if (onChangesMade) {
                  onChangesMade();
                }
              }}
              error={Boolean(formErrors.path)}
              helperText={formErrors.path || (<span>The extra part of the URL for the tenant (i.e. app.ipsecure.com/<b>{path ?? '<enter path>'}</b>).</span>)}
            />
          </Box>
          {tenant?.tenant !== 'ipsecure' && (
            <Box>
              <Box sx={{ color: loading ? 'rgba(0, 0, 0, 0.38)' : 'colors.black', fontWeight: '600', mb: .5, mt: -0.5 }}>Features</Box>
              <Select
                disabled={loading}
                displayEmpty
                multiple
                value={selectedFeatures}
                fullWidth
                sx={{ minWidth: '15vw', marginTop: '0px' }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return 'None';
                  }
                  return selected.map(f => f.description).join(', ');
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  setSelectedFeatures(value);
                  if (onChangesMade) {
                    onChangesMade();
                  }
                }}
                data-cy="profile_roles"
              >
                {features.map((f) => (
                  <MenuItem key={f.id} value={f}>
                    <Checkbox
                      checked={selectedFeatures.includes(f)}
                    />
                    {f.description}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
          {tenant?.tenant !== 'ipsecure' && (
            <PlatformRegionConfigDropdown
              initialSelection={selectedRegions}
              availablePlatformsAndRegions={regions}
              loading={loading}
              onChange={setSelectedRegions}
            />
          )}
          <Box>
            <Box sx={{ color: 'colors.black', fontWeight: '600', mb: .5, mt: -0.5 }}>Subscriptions</Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, borderRadius: '8px', bgcolor: 'greys.backgroundGrey', p: 3 }}>
              <Select
                disabled={loading}
                displayEmpty
                value={subscriptionScope}
                fullWidth
                sx={{ minWidth: '15vw', marginTop: '0px' }}
                onChange={(e) => {
                  const { value } = e.target;
                  setSubscriptionScope(value);
                  if (onChangesMade) {
                    onChangesMade();
                  }
                }}
                data-cy="profile_subscription_scope"
              >
                <MenuItem key="ACCOUNT" value="ACCOUNT">
                  Account Level Subscription Management
                </MenuItem>
                <MenuItem key="TENANT" value="TENANT">
                  Tenant Level Subscription Management
                </MenuItem>
              </Select>
              {subscriptionScope === 'TENANT' && (
                <Select
                  disabled={loading}
                  displayEmpty
                  value={subscriptionAccessControl}
                  fullWidth
                  sx={{ minWidth: '15vw', marginTop: '0px' }}
                  onChange={(e) => {
                    const { value } = e.target;
                    setSubscriptionAccessControl(value);
                    if (onChangesMade) {
                      onChangesMade();
                    }
                  }}
                  data-cy="profile_subscription_access_control"
                >
                  <MenuItem key="true" value={false}>
                    Subscription Monitoring: None
                  </MenuItem>
                  <MenuItem key="false" value>
                    Subscription Monitoring: Enabled
                  </MenuItem>
                </Select>
              )}
              {subscriptionScope === 'TENANT' && subscriptionAccessControl && (
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    disabled={loading}
                    id="subscription-id"
                    data-cy="subscription_id"
                    label="Stripe Subscription ID"
                    helperText="Starts with 'sub_'"
                    autoComplete="no"
                    type="text"
                    variant="standard"
                    fullWidth
                    required
                    value={subscriptionId || ''}
                    onChange={(e) => {
                      setSubscriptionId(e.target.value)
                      if (onChangesMade) {
                        onChangesMade();
                      }
                    }}
                  />
                  <DatePicker
                    label="Effective Date"
                    sx={{ minWidth: 260 }}
                    value={subscriptionAccessControlEffectiveDate}
                    onChange={(newValue) => {
                      setSubscriptionAccessControlEffectiveDate(newValue);
                    }}
                    slotProps={{
                      field: {
                        clearable: true,
                        onClear: () => setSubscriptionAccessControlEffectiveDate(null),
                      },
                    }}
                    data-cy="datepicker"
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4, mt: 1 }}>
            <Box sx={{ display: 'flex', gap: 2, pointerEvents: loading ? 'none' : null, opacity: loading ? 0.4 : 1, mt: 1 }}>
              <Box>
                {loginLogo && (
                  <Box
                    component="img"
                    sx={{ width: '140px', p: 2, background: '#f6f6f6' }}
                    alt="login logo"
                    src={loginLogo}
                  />
                )}
                {!loginLogo && (
                  <Box
                    component="div"
                    sx={{ width: '140px', p: 2, background: '#f6f6f6', fontSize: '12px', textAlign: 'center' }}
                    alt="login logo"
                  >
                    Upload Image
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  disabled={loading}
                  variant="outlined"
                  component="label"
                  sx={{ alignSelf: 'flex-start' }}
                >
                  Upload PNG Login Logo
                  <input
                    hidden
                    accept="image/png"
                    type="file"
                    onChange={handleSelectLoginLogo}
                  />
                </Button>
                <Box sx={{ fontSize: '12px', mt: 1 }}>
                  Logo used on login screen. The image is put on a light background.
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, pointerEvents: loading ? 'none' : null, opacity: loading ? 0.4 : 1 }}>
              <Box>
                {navLogo && (
                  <Box
                    component="img"
                    sx={{ width: '140px', p: 2, background: navColor }}
                    alt="navigation logo"
                    src={navLogo}
                  />
                )}
                {!navLogo && (
                  <Box
                    component="div"
                    sx={{ width: '140px', p: 2, background: navColor, fontSize: '12px', color: '#fff', textAlign: 'center' }}
                    alt="login logo"
                  >
                    Upload Image
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  disabled={loading}
                  variant="outlined"
                  component="label"
                  sx={{ alignSelf: 'flex-start' }}
                >
                  Upload PNG Navigation Logo
                  <input
                    hidden
                    accept="image/png"
                    type="file"
                    onChange={handleSelectNavigationLogo}
                  />
                </Button>
                <Box sx={{ fontSize: '12px', mt: 1 }}>
                  Logo used in the side navigation. The image is put on a dark background, and should preferably be all white.
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4 }}>
            <Box sx={{ display: 'flex', gap: 2, pointerEvents: loading ? 'none' : null, opacity: loading ? 0.4 : 1 }}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  width: '140px',
                  p: 2,
                  background: navColor,
                  fontSize: '12px',
                  color: '#fff',
                  borderRadius: '8px',
                  height: '52px',
                }}
              >
                <Box sx={{ mr: 1, display: 'flex', opacity: 0.6 }}>
                  <AsinManagerActiveIcon />
                </Box>
                <Typography sx={{ fontSize: '14px', fontWeight: '600', lineHeight: '24px' }}>
                  Navigation
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CompactPicker
                  disabled={loading}
                  colors={['#000000', '#1C1221', '#444444', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#881CE0']}
                  color={navColor}
                  onChange={color => {
                    setNavColor(color.hex);
                    if (onChangesMade) {
                      onChangesMade();
                    }
                  }}
                />
                <Box sx={{ fontSize: '12px', mt: 1 }}>
                  Pick a color or enter #HEX or RGB values.
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, pointerEvents: loading ? 'none' : null, opacity: loading ? 0.4 : 1 }}>
              <Box
                component="div"
                sx={{
                  display: 'table',
                  width: '140px',
                  height: '36px',
                  background: buttonColor,
                  fontSize: '15px',
                  fontWeight: '500',
                  color: '#fff',
                  textAlign: 'center',
                  borderRadius: '8px',
                }}
              >
                <Box component='span' sx={{ display: 'table-cell', lineHeight: 'normal', verticalAlign: 'middle' }}>
                  Button
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CompactPicker
                  colors={['#000000', '#1C1221', '#444444', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#881CE0']}
                  color={buttonColor}
                  onChange={color => {
                    setButtonColor(color.hex);
                    if (onChangesMade) {
                      onChangesMade();
                    }
                  }}
                />
                <Box sx={{ fontSize: '12px', mt: 1 }}>
                  Pick a color or enter #HEX or RGB values.
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </LocalizationProvider>
    </form>
  );
};

export default UpdateTenantForm;
